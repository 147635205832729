
.comment {

  padding: 10px;
  margin: 10px;
  border: 2px solid rgb(139, 139, 139);
  border-radius: 5px;
  position: relative;
  left: 10px;
}

.deleted-comment {
  border: 2px solid rgb(248, 127, 127);
}

.deleted-comment > .comment-status {
  font-weight: bold;
}
.restored-comment {
  border: 2px solid rgb(127, 149, 248);
}

.child-comments {
  position: relative;
  left: 0em;
}

.line-break {
  white-space: pre-wrap;
}
.comment-header {
  font-weight: bold;
  color: grey;
  border-bottom: 1px solid grey;
  margin-bottom: 5px;
  border-radius: 3px;
}

.comment-status {
  position: absolute;

  right: 8px;
  top: 4px;
}

.tags {
  padding: 5px;
}