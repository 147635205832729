.article { 
  text-align: center;
  min-width: 600px;
  position: relative;
  width: 80%;
  left: 10%;
}

.title {
  position:relative;
  width: 90%;
  left:5%;
}
.line-break {
  white-space: pre-wrap;
}

.article-content {
  position:relative;
  text-align: left;
  width: 94%;
  left:3%;
  padding: 10px;
  margin: 10px;
}