.tag {
  top: 10%;
  position: relative;
  display: inline;
}

.tag-content {
  margin: 3px;
  padding: 2px;
  position: relative;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 4px;
  background: rgb(241, 241, 241);

}

span.tag-content > a {
  color:dodgerblue;
}