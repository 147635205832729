
.article-summary {
  position: relative;
  border: 1px solid black;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  width: 80%;
}

.header {
  font-weight: bold;
}

.uncut {
  position:absolute;
  bottom: 3px;
  right: 3px;

}